import React from "react"

import styles from "./styles.module.scss"
import SEO from "../../components/seo"
import Header from "../../components/header"

// Images
import Main from "../../../static/map/main.png"

import Reception from "../../../static/map/reception.png"
import BakingACake from "../../../static/map/baking-a-cake.png"
import TheSpotlight from "../../../static/map/the-spotlight.png"
import ThePresents from "../../../static/map/the-presents.png"
import Ageing from "../../../static/map/ageing.png"
import AntiSocialDistanceParty from "../../../static/map/anti-social-distancing-birthday.png"
import DisposableCrown from "../../../static/map/disposable-crown.png"
import TheAltar from "../../../static/map/the-altar-of-the-i.png"

// Icons
import InfoMark from "../../../static/icons/info-mark-white.png"
import MuteMark from "../../../static/icons/mute-on-mark-white.png"
import EmergencyMark from "../../../static/icons/emergency-mark-white.png"
import MapTile from "../../components/map-tile"

export default function DepartmentsPage() {
  return (
    <>
      <SEO title="Departments" />
      <Header />
      <div className={styles.rooms}>
        <div className={styles.rooms__container}>
          <div className={styles.rooms__container__legend}>
            <h2>Floor plan</h2>
            <h3>Departments</h3>
            <ul>
              <li>
                <MapTile
                  image={Reception}
                  link="/departments/reception/"
                  name="Reception"
                />
              </li>
              <li>
                <MapTile
                  image={BakingACake}
                  link="/departments/baking-a-cake/"
                  name="Baking A Cake"
                />
              </li>
              <li>
                <MapTile
                  image={TheSpotlight}
                  link="/departments/the-spotlight/"
                  name="The Spotlight"
                />
              </li>
              <li>
                <MapTile
                  image={ThePresents}
                  link="/departments/the-presents/"
                  name="The Present(s)"
                />
              </li>
              <li>
                <MapTile
                  image={Ageing}
                  link="/departments/ageing/"
                  name="Ageing"
                />
              </li>
              <li>
                <MapTile
                  image={AntiSocialDistanceParty}
                  link="/departments/anti-social-distance-party/"
                  name="Anti Social Distance Party"
                />
              </li>
              <li>
                <MapTile
                  image={DisposableCrown}
                  link="/departments/disposable-crown/"
                  name="Disposable Crown"
                />
              </li>
              <li>
                <MapTile
                  image={TheAltar}
                  link="/departments/the-altar-of-the-i/"
                  name="The Altar of the I"
                />
              </li>
            </ul>
            <h3>Other</h3>
            <ul className={styles.rooms__container__legend__icons}>
              <li>
                <img src={EmergencyMark} alt="Introduction Icon" />
                Emergency exit
              </li>
              <li>
                <img src={InfoMark} alt="Introduction Icon" />
                Introduction texts
              </li>
              <li>
                <img src={MuteMark} alt="Mute Icon" />
                Sound
              </li>
            </ul>
          </div>
          <div className={styles.rooms__container__image}>
            <img id="mapImage" src={Main} alt="Main" />
          </div>
        </div>
      </div>
    </>
  )
}
