import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import MapMain from "../../../static/map/main.png"

import styles from "./styles.module.scss"

export default function MapTile({ name, image, link }) {
  function setImage() {
    document.getElementById("mapImage").src = image
  }

  function resetImage() {
    document.getElementById("mapImage").src = MapMain
  }

  return (
    <Link
      onMouseEnter={() => setImage()}
      onMouseLeave={() => resetImage()}
      className={`${styles.tile}`}
      to={link}
    >
      {name}
    </Link>
  )
}

MapTile.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
